import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';

const TimeSelectForm = () => {
  const [formData, setFormData] = useState({
    entryTime: '',
    exitTime: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let h = 10; h < 16; h++) {
      for (let m = 0; m < 60; m += 30) {
        const hour = h.toString().padStart(2, '0');
        const minute = m.toString().padStart(2, '0');
        times.push(`${hour}:${minute}`);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  return (
    <Form>
      <Form.Group as={Col} sm={6} controlId="formEntryTime">
        <Form.Label column sm={2}>
          Entry Time
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as="select"
            name="entryTime"
            value={formData.entryTime}
            onChange={handleChange}
            required
          >
            <option value="">Select Time</option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>
                {time}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
      
      <Form.Group as={Col} sm={6} controlId="formExitTime">
        <Form.Label column sm={2}>
          Exit Time
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            as="select"
            name="exitTime"
            value={formData.exitTime}
            onChange={handleChange}
            required
          >
            <option value="">Select Time</option>
            {timeOptions.map((time, index) => (
              <option key={index} value={time}>
                {time}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default TimeSelectForm;
