import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Container, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "40px",
    height: "40px",
    boxShadow: state.isFocused ? null : null,
  }),
};

const indexOptions = [
  { value: "banknifty", label: "BankNifty" },
  { value: "nifty", label: "Nifty" },
  { value: "finnifty", label: "FinNifty" },
  { value: "midcapnifty", label: "MidcapNifty" },
  { value: "sensex", label: "Sensex" },
];

const typeOptions = [
  { value: "ce", label: "Call" },
  { value: "pe", label: "Put" },
];

const actionOptions = [
  { value: "buy", label: "Buy" },
  { value: "sell", label: "Sell" },
];

const exitIndexUpDownOptions = [
  { value: "+", label: "+" },
  { value: "-", label: "-" },
];

const StrategyFormForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    strategy: "strangle", // Added strategy to manage the selected strategy
    index: "nifty",
    startDate: "",
    endDate: "",
    entryTime: "09:15",
    exitTime: "15:29",
    entryIndex: 23500,
    exitSL: "",
    exitTP: "",
    exitIndexUpDown: "+",
    indexUp: "",
    indexDown: "",
    ratio: "",
    legs: [
      { gap: "", type: "ce", action: "buy" },
      { gap: "", type: "pe", action: "sell" },
    ],
  });
  const [timeError, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, section, idx) => {
    const { name, value } = e.target;
    console.log(name, value, "==>");
    setError("");

    if (section === "legs") {
      const legs = [...formData.legs];
      legs[idx][name] = value;
      setFormData({ ...formData, legs });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (selectedOption, actionMeta, section, idx) => {
    if (selectedOption) {
      if (section === "legs") {
        const legs = [...formData.legs];
        legs[idx][actionMeta.name] = selectedOption.value;
        setFormData({ ...formData, legs });
      } else {
        setFormData({ ...formData, [actionMeta.name]: selectedOption.value });
      }
    }
  };

  const handleClear = () => {
    localStorage.removeItem("strategyFormData"); // Clear localStorage
    setFormData({
      strategy: "strangle",
      index: "nifty",
      startDate: "",
      endDate: "",
      entryTime: "",
      exitTime: "",
      entryIndex: "",
      exitSL: "",
      exitTP: "",
      exitIndexUpDown: "+",
      indexUp: "",
      indexDown: "",
      ratio: "",
      legs: [
        { gap: "", type: "", action: "" },
        { gap: "", type: "", action: "" },
      ],
    });
  };

  // const handleAddLeg = () => {
  //   const newLeg = { gap: "", type: "ce", action: "buy" };
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     legs: [...prevData.legs, newLeg],
  //   }));
  // };

  // const handleRemoveLeg = () => {
  //   if (formData.legs.length > 1) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       legs: prevData.legs.slice(0, -1),
  //     }));
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let url;
      let data;
      localStorage.setItem("strategyFormData", JSON.stringify(formData));

      if (formData.strategy === "strangle") {
        url = "https://stockrin-mern-api.galitein.com/api/strangle";
        data = {
          ...formData,
        };
      } else if (formData.strategy === "longIronCondor") {
        url = "https://stockrin-mern-api.galitein.com/api/longIronCondor";
        data = {
          strategy: formData.strategy, // Added strategy to manage the selected strategy
          index: formData.index,
          startDate: formData.startDate,
          endDate: formData.endDate,
          legs: formData.legs,
          ratio: formData.ratio,
        };
      }

      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        toast.success("Form submitted successfully!");
        // localStorage.setItem("strategyFormData", JSON.stringify(formData));
        navigate("/table", {
          state: {
            trades: response.data.data,
            formData,
            strategy: formData.strategy,
          },
        });
      } else {
        toast.error("Error submitting form: " + response.statusText);
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error("Something went wrong. Please try again later.");
      }
      if (error.response.status === 404) {
        toast.error(error.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleStrategyChange = (e) => {
    const selectedStrategy = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      strategy: selectedStrategy,
      legs:
        selectedStrategy === "longIronCondor"
          ? [
              ...prevData.legs,
              { gap: "", type: "ce", action: "buy" },
              { gap: "", type: "pe", action: "sell" },
            ]
          : prevData.legs.slice(0, 2), // Reset to 2 legs if strangle
    }));
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem("strategyFormData");
    console.log("calll=>");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, [location.key]); // Trigger on location change

  // // Save form data to localStorage whenever it changes
  // useEffect(() => {
  //   localStorage.setItem("strategyFormData", JSON.stringify(formData));
  // }, [formData]);

  return (
    <Container>
      <h2 className="mt-4">Trading Strategy</h2>
      {loading ? (
        <div className="d-flex justify-content-center my-3">
          <Spinner animation="border" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Index
            </Form.Label>
            <Col sm={10}>
              <Select
                name="index"
                value={indexOptions.find(
                  (option) => option.value === formData.index
                )}
                onChange={(selectedOption, actionMeta) =>
                  handleSelectChange(selectedOption, actionMeta)
                }
                options={indexOptions}
                styles={customStyles}
                isSearchable={false}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group className="mb-3 d-flex align-items-center">
            <Form.Label column sm={2}>
              Strategy
            </Form.Label>
            <div className="col-sm-4 d-flex align-items-center">
              <div>
                <Form.Check
                  type="radio"
                  label="Strangle"
                  name="strategy"
                  value="strangle"
                  checked={formData.strategy === "strangle"}
                  onChange={(e) => handleStrategyChange(e)}
                  className="radio-label me-4 pl-2"
                />
              </div>
              <div>
                <Form.Check
                  type="radio"
                  label="Long Iron Condor"
                  name="strategy"
                  value="longIronCondor"
                  checked={formData.strategy === "longIronCondor"}
                  onChange={(e) => handleStrategyChange(e)}
                  className="radio-label pl-2"
                />
              </div>
            </div>
            <div className="col-sm-3">
              {formData.strategy !== "strangle" && (
                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 me-3">Ratio</Form.Label>
                  <Form.Control
                    className="ml-5"
                    type="number"
                    name="ratio"
                    placeholder="Ratio"
                    value={formData.ratio}
                    onChange={(e) => handleChange(e)}
                    required={
                      formData.strategy === "longIronCondor" ? true : false
                    }
                  />
                </div>
              )}
            </div>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Start Date
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={(e) => handleChange(e)}
                required
              />
            </Col>
            <Form.Label column sm={2}>
              End Date
            </Form.Label>
            <Col sm={4}>
              <Form.Control
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={(e) => handleChange(e)}
                required
              />
            </Col>
          </Form.Group>

          {formData.strategy === "strangle" && (
            <>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Entry Time
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="time"
                    name="entryTime"
                    value={formData.entryTime}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Col>
                <Form.Label column sm={2}>
                  Exit Time
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="time"
                    name="exitTime"
                    value={formData.exitTime}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  SL
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    name="exitSL"
                    placeholder="SL"
                    value={formData.exitSL}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Form.Label column sm={2}>
                  TP
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    name="exitTP"
                    placeholder="TP"
                    value={formData.exitTP}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Index Down
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    name="indexDown"
                    placeholder="Index Down"
                    value={formData.indexDown}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                <Form.Label column sm={2}>
                  Index Up
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    type="number"
                    name="indexUp"
                    placeholder="Index Up"
                    value={formData.indexUp}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {formData.legs.map((leg, idx) => (
            <Form.Group as={Row} className="mb-3" key={idx}>
              <Form.Label column sm={2}>{`Leg ${idx + 1}`}</Form.Label>
              <Col sm={3}>
                <Select
                  name="action"
                  value={actionOptions.find(
                    (option) => option.value === leg.action
                  )}
                  onChange={(selectedOption, actionMeta) =>
                    handleSelectChange(selectedOption, actionMeta, "legs", idx)
                  }
                  options={actionOptions}
                  styles={customStyles}
                  isSearchable={false}
                  required
                />
              </Col>
              <Col sm={2}>
                <Form.Control
                  type="number"
                  name="gap"
                  placeholder="Gap"
                  value={leg.gap}
                  onChange={(e) => handleChange(e, "legs", idx)}
                  required
                />
              </Col>
              <Col sm={3}>
                <Select
                  name="type"
                  value={typeOptions.find(
                    (option) => option.value === leg.type
                  )}
                  onChange={(selectedOption, actionMeta) =>
                    handleSelectChange(selectedOption, actionMeta, "legs", idx)
                  }
                  options={typeOptions}
                  styles={customStyles}
                  isSearchable={false}
                  required
                />
              </Col>
            </Form.Group>
          ))}

          <Button
            variant="secondary"
            onClick={() => handleClear()}
            className="me-2"
          >
            Clear
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            Submit
          </Button>
        </Form>
      )}

      <ToastContainer />
    </Container>
  );
};

export default StrategyFormForm;
