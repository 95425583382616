import React from "react";
import { Container, Table, Card, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router";

const TradesTable = () => {
  const location = useLocation();

  const { trades, formData, strategy } = location.state;

  return (
    <>
      <Container className="my-4">
        {formData && (
          <Card className="mb-4">
            <Card.Body>
              <h3 className="mb-3">Form Data</h3>
              <Row className="mb-2">
                <Col>
                  <strong>Index:</strong> {formData.index}
                </Col>
                {strategy !== "strangle" && (
                  <Col>
                    <strong>Ratio</strong> {formData.ratio}
                  </Col>
                )}
              </Row>
              <Row className="mb-2">
                <Col>
                  <strong>Start Date:</strong>{" "}
                  {formData?.startDate?.split("-").reverse().join("-")}
                </Col>
                <Col>
                  <strong>End Date:</strong>{" "}
                  {formData?.endDate?.split("-").reverse().join("-")}
                </Col>
              </Row>
              {strategy === "strangle" && (
                <>
                  {" "}
                  <Row className="mb-2">
                    <Col>
                      <strong>Entry Time:</strong> {formData.entryTime}
                    </Col>
                    <Col>
                      <strong>Exit Time:</strong> {formData.exitTime}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <strong>Stop Loss:</strong> {formData.exitSL}
                    </Col>
                    <Col>
                      <strong>Take Profit:</strong> {formData.exitTP}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <strong>Index Down:</strong>{" "}
                      {formData.indexDown ? formData.indexDown : "00"}
                    </Col>
                    <Col>
                      <strong>Index Up:</strong>{" "}
                      {formData.indexUp ? formData.indexUp : "00"}
                    </Col>
                  </Row>
                </>
              )}
              <div>
                <strong>Legs:</strong>
                {formData.legs.map((leg, legIndex) => (
                  <div key={legIndex}>
                    {`Leg ${legIndex + 1}: ${leg.action} ${leg.gap} ${
                      leg.type
                    }`}
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>
      <div className="trade-main">
        <h2 className="mt-4 text-center">Trade Data</h2>
        <div className="trade-table">
          <Table striped bordered hover className="mt-4 overflow-x-scroll">
            <thead>
              {strategy === "strangle" ? (
                <tr>
                  <th>Date</th>
                  <th>Strike Price</th>
                  <th>Action</th>
                  <th>Leg Index</th>
                  <th>Type</th>
                  <th>Total Amount</th>
                  <th>Entry Price</th>
                  <th>Exit Price</th>
                  <th>Exit SP</th>
                  <th>Leg PNL</th>
                  <th>Total PNL</th>
                  <th>Exit Reason</th>
                  <th>Exit Time</th>
                </tr>
              ) : (
                <tr>
                  <th>ExpireDate</th>
                  <th>Entry Time</th>
                  <th>Exit Times</th>
                  <th>Symbol</th>
                  <th>Leg Gap</th>
                  <th>Lot Size</th>
                  <th>Investment</th>
                  <th>Entry Index</th>
                  <th>Exit Index</th>

                  {[1, 2, 3, 4].map((indexValue) => {
                    return (
                      <>
                        <th>{`Entry Price${indexValue}`}</th>
                        <th>{`Exit Price${indexValue}`}</th>
                      </>
                    );
                  })}
                  <th>Strike Price</th>
                  <th>Exit SP Amount</th>
                  <th>Exit sp (%)</th>
                  <th>Ratio</th>
                  <th>Max Profit</th>
                  <th>Max Loss</th>
                  <th>Total PNL</th>
                  <th>Total PNL(%)</th>
                  <th>Breakeven Higher</th>
                  <th>Breakeven Higher(%)</th>
                  <th>Breakeven Lower</th>
                  <th>Breakeven Lower(%)</th>

                  {[1, 2, 3, 4].map((indexValue) => {
                    return (
                      <>
                        <th>{`Strick Price${indexValue}`}</th>
                        <th>{`SP Type${indexValue}`}</th>
                      </>
                    );
                  })}

                  <th>Exit Url</th>
                </tr>
              )}
            </thead>
            <tbody>
              {trades &&
                trades.length > 0 &&
                trades.map((trade, index) =>
                  strategy === "strangle" ? (
                    <React.Fragment key={index}>
                      <td
                        colSpan="13"
                        style={{ backgroundColor: "khaki" }}
                        className="text-center font-weight-bold border-1"
                      >
                        {trade.date}
                      </td>
                      {trade.legs.map((leg, legIndex) => (
                        <tr key={`${index}-${legIndex}`}>
                          <td>{leg.date}</td>
                          <td>{leg.strickPrice}</td>
                          <td>{leg.action}</td>
                          <td>{leg.legIndex}</td>
                          <td>{leg.type}</td>
                          <td>{leg.totalAmount}</td>
                          <td>{leg.EntryPrice}</td>
                          <td>{leg.exitPrice}</td>
                          <td>{leg.exitStrickPrice}</td>
                          <td>{leg.legPnl}</td>
                          <td>{leg.profitLoss}</td>
                          <td>{leg.exitReason}</td>
                          <td>{leg.exitTime}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={index}>
                      {/* <td
                        colSpan="26"
                        style={{ backgroundColor: "khaki" }}
                        className="text-center font-weight-bold border-1"
                      >
                        {trade.date}
                      </td> */}
                      {!trade.legs.reason ? (
                        <tr key={index}>
                          <td>{trade.date}</td>
                          <td>{trade.legs.entryTime}</td>
                          <td>{trade.legs.exitTimes}</td>
                          <td>{trade.legs.symbol}</td>
                          <td>{trade.legs.gapBetweenLeg}</td>
                          <td>{trade.legs.lotSize}</td>
                          <td>{trade.legs.investmentAmount}</td>
                          <td>{trade.legs.index}</td>
                          <td>{trade.legs.exitStrick}</td>
                          {trade.legs.legdetails.map((tradsDetails, index) => {
                            return (
                              <>
                                <td>{tradsDetails.price}</td>
                                <td>{tradsDetails.exitPrice}</td>
                              </>
                            );
                          })}
                          <td>{trade.legs.strikePrice}</td>
                          <td>{trade.legs.exitSp}</td>
                          <td>{trade.legs.exitSpPer}</td>
                          <td>{trade.legs.ratio}</td>
                          <td>{trade.legs.maxProfit}</td>
                          <td>{trade.legs.maxLoss}</td>
                          <td>{trade.legs.totalpnl}</td>
                          <td>{trade.legs.totalPnlPer}</td>
                          <td>{trade.legs.breakevenHigher}</td>
                          <td>{trade.legs.breakevenHigherPer}</td>
                          <td>{trade.legs.breakevenLower}</td>
                          <td>{trade.legs.breakevenLowerPer}</td>
                          {trade.legs.legdetails.map((tradsDetails, index) => {
                            return (
                              <>
                                <td>{tradsDetails.strickPrice}</td>
                                <td>{tradsDetails.type}</td>
                              </>
                            );
                          })}

                          <td>
                            <a href={trade.legs.url} target="_blank">
                              Url
                            </a>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{trade.date}</td>
                          <td colSpan="38" className="text-center">
                            {trade.legs.reason}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TradesTable;
