import { Routes, Route } from 'react-router-dom';
import "./App.css";

import StrategyForm from "./StrategyForm";
import TradesTable from "./TradeTable";
import TimeSelectForm from './TimeSelectForm';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<StrategyForm />} />
        <Route path="/table" element={<TradesTable />} />
        <Route path="/demo" element={<TimeSelectForm />} />
      </Routes> 
    </div>
  );
}

export default App;
